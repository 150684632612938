<template>

  <b-card no-body>
    <div class="m-1">
      <b-row>
        <b-col cols="12" md="7" class="d-flex align-items-center justify-content-start mb-1 mb-md-0 per-page-select">
          <v-select
              v-model="perPage"
              class="per-page-selector d-inline-block mx-50"
              :options="perPageOptions"
              :clearable="false"/>
          <v-select
              v-model="filterCategory"
              :options="filterCategoryOptions"
              :reduce="val => val.value"
              :clearable="true"
              class="per-page-selector d-inline-block mx-50 statusFilter"/>
          <v-select
              v-model="filterWorkplace"
              :options="filterWorkplaceOptions"
              :reduce="val => val.value"
              :clearable="true"
              class="per-page-selector d-inline-block mx-50 statusFilter"/>
          <v-select
              v-model="filterStatus"
              :options="filterStatusOptions"
              :reduce="val => val.value"
              :clearable="true"
              class="per-page-selector d-inline-block mx-50 statusFilter"/>
        </b-col>
        <b-col cols="12" md="5">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input v-model="searchQuery" class="mr-1" placeholder="Arama..."/>
            <header-table :new-route="{ name: 'asset-add' }" :re-fetch-data="reFetchData" :export-excel="exportExcel"></header-table>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
        ref="refListTable"
        primary-key="id"
        empty-text="Kayıt bulunamadı"
        :class="`position-relative table-sticky ${totalCount > 0 && totalCount < 5 ? 'dropdown-h150': ''}`"
        :items="fetchList"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        responsive striped bordered hover show-empty>

      <template #cell(id)="data">
        <id-column-table :data="data.value" :route-name="'asset-edit'"></id-column-table>
      </template>

      <template #cell(isActive)="data">
        <active-column-table :data="data.value"></active-column-table>
      </template>

      <template #cell(invoiceDate)="data">
        <date-column-table :data="data.value"></date-column-table>
      </template>

      <template #cell(invoiceNo)="data">
        <div class="text-nowrap">{{ data.value }}</div>
      </template>

      <template #cell(name)="data">
        <div class="text-nowrap">{{ data.value }}</div>
      </template>

      <template #cell(assetCategoryName)="data">
        <div class="text-nowrap">{{ data.value }}</div>
      </template>

      <template #cell(assetBrandName)="data">
        <div class="text-nowrap">{{ data.value }}</div>
      </template>

      <template #cell(modelName)="data">
        <div class="text-nowrap">{{ data.value }}</div>
      </template>

      <template #cell(serialNumber)="data">
        <div class="text-nowrap">{{ data.value }}</div>
      </template>

      <template #cell(organizationName)="data">
        <div class="text-nowrap">{{ data.value }}</div>
      </template>

      <template #cell(actions)="data">
        <div class="text-nowrap">
          <b-dropdown text="Dropdown" variant="link" no-caret toggle-class="p-0" dropleft>
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
            </template>
            <b-dropdown-item :to="{ name: 'asset-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">Değiştir</span>
            </b-dropdown-item>
            <b-dropdown-item @click="labelRecord(data.item)">
              <feather-icon icon="PrinterIcon"/>
              <span class="align-middle ml-50">Etiket</span>
            </b-dropdown-item>
            <remove-button-table :data="data" :re-fetch-data="reFetchData" :dropdown="true"></remove-button-table>
          </b-dropdown>
          <feather-icon :id="`row-${data.item.id}-prev`" icon="EyeIcon" size="16" @click="previewRecord(data.item)" class="mx-1"/>
          <b-tooltip title="Görüntüle" class="cursor-pointer" :target="`row-${data.item.id}-prev`"/>
        </div>
      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>
        <footer-table :data-meta="dataMeta"></footer-table>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" v-model="currentPage" :total-rows="totalCount" :per-page="perPage" first-number last-number>
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18"/>
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18"/>
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>

    <Overlay :busy="busy"></Overlay>

    <preview :is-preview-modal.sync="isPreviewModal" :data-record.sync="dataPreviewRecord"/>

    <div id="printThis" class="d-none">
      <div style="display: flex; justify-content: space-between">
        <div>
          <p style="font-size: 14px; margin: 15px 0 0;font-weight: bold;">{{ dataLabelInfo !== null ? dataLabelInfo.assetBrandName : '' }}</p>
          <p style="font-size: 14px; margin: 5px 0 0;font-weight: bold;">{{ dataLabelInfo !== null ? dataLabelInfo.modelName : '' }}</p>
        </div>
        <qr-code :text="'https://portal.mustersoft.com/asset/asset/search/' + (dataLabelInfo !== null ? dataLabelInfo.id : 0)" :size="70" id="barcode"/>
      </div>
      <div style="flex-grow: 1; margin-top: 20px;">
        <p style="margin: 0;font-size: 14px; font-weight: bold;">SN: {{ dataLabelInfo !== null ? dataLabelInfo.serialNumber : '' }}</p>
      </div>
    </div>

  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BButtonToolbar, BButtonGroup, BOverlay, BModal, BCardText,
} from 'bootstrap-vue'
import {
  FooterTable, RemoveButtonTable, EditButtonTable, DateColumnTable,
  ActiveColumnTable, BooleanColumnTable, IdColumnTable, HeaderTable
} from '@/components/Table';

import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import store from '@/store'
import useList from './useList'
import storeModule from './store'
import definitionModule from "@/views/system/definition/store"
import categoryModule from "@/views/asset/category/store"
import Overlay from "@/components/Overlay.vue"
import Preview from "@/views/asset/asset/Preview.vue"
import {getApiFile} from "@core/utils/filter"
import Vue from "vue";
import {toastMessage} from "@core/utils/utils";
import {useToast} from "vue-toastification/composition";
import workplaceModule from "@/views/organization/workplace/store";

export default {
  components: {
    BCardText,
    BModal,

    Preview, HeaderTable, FooterTable, RemoveButtonTable, EditButtonTable,
    DateColumnTable, ActiveColumnTable, BooleanColumnTable, IdColumnTable,

    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem,
    BPagination, BTooltip, BButtonToolbar, BButtonGroup, BOverlay,

    vSelect,
    Overlay,
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    const STORE_DEFINITION_MODULE_NAME = 'store-definition'
    const STORE_CATEGORY_MODULE_NAME = 'store-category'
    const STORE_WORKPLACE_MODULE_NAME = 'store-workplace'

    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_DEFINITION_MODULE_NAME, definitionModule)
      store.registerModule(STORE_CATEGORY_MODULE_NAME, categoryModule)
      store.registerModule(STORE_WORKPLACE_MODULE_NAME, workplaceModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_DEFINITION_MODULE_NAME)
        store.unregisterModule(STORE_CATEGORY_MODULE_NAME)
        store.unregisterModule(STORE_WORKPLACE_MODULE_NAME)
      }
    })

    const toast = useToast()

    const {
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      busy,
      filterCategory,
      filterWorkplace,
      filterStatus,

      fetchList,
      reFetchData,
    } = useList()

    const dataPreviewRecord = ref({})
    const isPreviewModal = ref(false)
    const previewRecord = (dataItem) => {
      dataPreviewRecord.value = dataItem
      isPreviewModal.value = true
    }

    const dataLabelInfo = ref(null)
    const labelRecord = (dataItem) => {
      dataLabelInfo.value = dataItem

      setTimeout(function () {
        document.getElementById("printThis").classList.remove("d-none");
        const divContents = document.getElementById("printThis").innerHTML;
        const labelWindow = window.open('', '');
        labelWindow.document.write('<html lang="tr">');
        labelWindow.document.write('<head><title>Etiket</title></head>');
        labelWindow.document.write(' <meta charSet="UTF-8">');
        labelWindow.document.write(' <meta name="viewport" content="width=device-width, initial-scale=1.0">\n');
        labelWindow.document.write('</head>');
        labelWindow.document.write('<body style="margin-left: 40px">');
        labelWindow.document.write(divContents);
        labelWindow.document.write('</body></html>');
        labelWindow.document.close();
        labelWindow.print();

        dataLabelInfo.value = null
        document.getElementById("printThis").classList.add("d-none");
      }, 1000);
    }

    const exportExcel = () => {
      Vue.swal({
        title: 'Onaylıyor musunuz?',
        text: 'Excele aktarım yapılacaktır!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet, Aktar',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('store/export', {
            statusId: filterStatus.value,
            categoryId: filterCategory.value,
            workplaceId: filterWorkplace.value,
          }).then(response => {
            if (response.data.success) {
              window.open(getApiFile(response.data.data), '_blank')
            }
          }).catch(error => {
            Vue.swal({
              icon: 'error',
              title: 'Hata!',
              text: error.response.data.message,
              confirmButtonText: 'Tamam',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
        }
      })
    }

    const filterStatusOptions = ref([])
    store.dispatch('store-definition/definitionList', {groupKey: 'ASSET_STATUS_TYPE'}).then(response => {
      response.data.data.forEach((value, index) => {
        filterStatusOptions.value.push({label: value.name, value: value.key})
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    })

    const filterCategoryOptions = ref([])
    store.dispatch('store-category/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          filterCategoryOptions.value.push({label: value.name, value: value.id})
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    })

    const filterWorkplaceOptions = ref([])
    store.dispatch('store-workplace/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          filterWorkplaceOptions.value.push({label: value.shortTitle, value: value.id})
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    })

    return {
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      busy,
      isPreviewModal,
      dataPreviewRecord,
      filterStatus,
      filterStatusOptions,
      filterCategory,
      filterCategoryOptions,
      filterWorkplace,
      filterWorkplaceOptions,
      dataLabelInfo,

      fetchList,
      reFetchData,
      previewRecord,
      labelRecord,
      getApiFile,
      exportExcel,
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.statusFilter {
  min-width: 25%;
}

.perPageFilter {
  min-width: 12%;
}

</style>