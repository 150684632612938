import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchItems(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/assets', {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        previewItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/assets/view/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/assets/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/assets', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        editItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .put('/assets', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/assets/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchTransactionList(ctx, {assetId, queryParams}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/assettransactions/${assetId}`, {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        previewTransaction(ctx, {assetId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/assettransactions/${assetId}/view/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchTransaction(ctx, {assetId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/assettransactions/${assetId}/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addTransaction(ctx, {assetId, dataInfo}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/assettransactions/${assetId}`, dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        editTransaction(ctx, {assetId, dataInfo}) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/assettransactions/${assetId}`, dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeTransaction(ctx, {assetId, id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/assettransactions/${assetId}/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        export(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/assets/export', {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchExpireWarrantyDate(ctx, {assetId}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/assettransactions/${assetId}/get-expire-warranty-date`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addTransactionDocument(ctx, {assetTransactionId , dataInfo}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/assettransactions/document-add/${assetTransactionId }`, dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}